@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-body;
}

p {
  max-width: 800px;
}
